'use client';

import { Component, type ReactNode } from 'react';

import type { Translations } from '@/types';

interface ThumbnailErrorBoundaryProps {
    children: ReactNode;
    translations: Translations;
    reset?: () => void;
}

interface ThumbnailErrorBoundaryState {
    hasError: boolean;
}

export class ThumbnailErrorBoundary extends Component<
    ThumbnailErrorBoundaryProps,
    ThumbnailErrorBoundaryState
> {
    constructor(props: ThumbnailErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): ThumbnailErrorBoundaryState {
        return { hasError: true };
    }

    override componentDidUpdate(prevProps: ThumbnailErrorBoundaryProps) {
        if (prevProps.children !== this.props.children && this.state.hasError) {
            this.setState({ hasError: false });
        }
    }

    override async componentDidCatch(
        error: Error,
        errorInfo: React.ErrorInfo
    ): Promise<void> {
        if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
            const Sentry = await import('@sentry/nextjs');
            const extra = {
                componentStack: errorInfo.componentStack,
                errorBoundaryName: 'ThumbnailErrorBoundary',
            };
            Sentry.captureException(error, { extra });
        }
    }

    override render() {
        if (this.state.hasError) {
            return (
                <div
                    className="rounded-lg bg-destructive/15 p-4 text-base text-destructive"
                    role="alert"
                    aria-label={this.props.translations.form.aria.errorMessage}
                >
                    {this.props.translations.form.errors.fetchFailed}
                </div>
            );
        }

        return this.props.children;
    }
}
