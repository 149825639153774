import { fetchWithRetry } from '@/lib/utils';
import {
    SimpleCache,
    ThumbnailQuality,
    YouTubeVideoType,
    type YouTubeVideo,
    type ThumbnailResponse,
    type Translations,
} from '@/types';

const CACHE_EXPIRY = 24 * 60 * 60 * 1000;
const FETCH_TIMEOUT = 5000;
const MAX_RETRIES = 3;

const videoIdCache = new SimpleCache<string, string>(CACHE_EXPIRY);
const thumbnailCache = new SimpleCache<string, string>(CACHE_EXPIRY);

const VIDEO_ID_REGEX = /^[A-Za-z0-9_-]{11}$/;

const URL_PATTERNS: Array<{
    pattern: RegExp;
    type: YouTubeVideoType;
    createCanonicalUrl: (id: string) => string;
}> = [
    {
        pattern:
            /^https?:\/\/(?:www\.)?youtu\.be\/([A-Za-z0-9_-]{11})(?:\?.*)?$/,
        type: YouTubeVideoType.VIDEO,
        createCanonicalUrl: (id) => `https://youtube.com/watch?v=${id}`,
    },
    {
        pattern:
            /^https?:\/\/(?:www\.|m\.)?youtube\.com\/watch\?(?:.*&)?v=([A-Za-z0-9_-]{11})(?:&.*)?$/,
        type: YouTubeVideoType.VIDEO,
        createCanonicalUrl: (id) => `https://youtube.com/watch?v=${id}`,
    },
    {
        pattern:
            /^https?:\/\/(?:www\.)?youtube\.com\/embed\/([A-Za-z0-9_-]{11})(?:\?.*)?$/,
        type: YouTubeVideoType.EMBED,
        createCanonicalUrl: (id) => `https://youtube.com/embed/${id}`,
    },
    {
        pattern:
            /^https?:\/\/(?:www\.)?youtube\.com\/v\/([A-Za-z0-9_-]{11})(?:\?.*)?$/,
        type: YouTubeVideoType.VIDEO,
        createCanonicalUrl: (id) => `https://youtube.com/watch?v=${id}`,
    },
    {
        pattern:
            /^https?:\/\/(?:www\.)?youtube\.com\/shorts\/([A-Za-z0-9_-]{11})(?:\?.*)?$/,
        type: YouTubeVideoType.SHORT,
        createCanonicalUrl: (id) => `https://youtube.com/shorts/${id}`,
    },
] as const;

export function isValidYouTubeUrl(url: string): boolean {
    if (!url || typeof url !== 'string') return false;

    try {
        const cleanUrl = cleanYouTubeUrl(url);
        return URL_PATTERNS.some(({ pattern }) => pattern.test(cleanUrl));
    } catch {
        return false;
    }
}

export function cleanYouTubeUrl(url: string): string {
    return url
        .trim()
        .replace(/\s+/g, '')
        .replace(/[\u200B-\u200D\uFEFF]/g, '')
        .replace(/^\/\//, 'https://');
}

function generateCacheKey(video: YouTubeVideo): string {
    return `${video.type}_${video.id}`;
}

export function parseYouTubeUrl(url: string): YouTubeVideo | null {
    if (!url || typeof url !== 'string') return null;

    const cleanedUrl = cleanYouTubeUrl(url);

    const cachedId = videoIdCache.get(cleanedUrl);
    if (cachedId) {
        for (const { pattern, type, createCanonicalUrl } of URL_PATTERNS) {
            if (pattern.test(cleanedUrl)) {
                return {
                    id: cachedId,
                    type,
                    canonicalUrl: createCanonicalUrl(cachedId),
                };
            }
        }
    }

    for (const { pattern, type, createCanonicalUrl } of URL_PATTERNS) {
        const match = cleanedUrl.match(pattern);
        if (match?.[1] && VIDEO_ID_REGEX.test(match[1])) {
            const video: YouTubeVideo = {
                id: match[1],
                type,
                canonicalUrl: createCanonicalUrl(match[1]),
            };
            videoIdCache.set(cleanedUrl, video.id);
            return video;
        }
    }

    try {
        const urlObj = new URL(cleanedUrl);
        const possibleId = urlObj.searchParams.get('v');
        if (possibleId && VIDEO_ID_REGEX.test(possibleId)) {
            const video: YouTubeVideo = {
                id: possibleId,
                type: YouTubeVideoType.VIDEO,
                canonicalUrl: `https://youtube.com/watch?v=${possibleId}`,
            };
            videoIdCache.set(cleanedUrl, video.id);
            return video;
        }
    } catch {
        return null;
    }

    return null;
}

async function checkImageAvailability(url: string): Promise<boolean> {
    return fetchWithRetry(async () => {
        const response = await fetch(url, {
            method: 'HEAD',
            signal: AbortSignal.timeout(FETCH_TIMEOUT),
        });
        return response.ok;
    });
}

export function getErrorMessage(error: string, t: Translations): string {
    const key = error as keyof typeof t.youtube.errors;
    return t.youtube.errors[key] || error;
}

export async function getThumbnails(
    video: YouTubeVideo,
    t: Translations
): Promise<ThumbnailResponse> {
    if (!VIDEO_ID_REGEX.test(video.id)) {
        return { error: 'Invalid video ID format' };
    }

    const cacheKey = generateCacheKey(video);
    const cachedThumbnail = thumbnailCache.get(cacheKey);
    if (cachedThumbnail) {
        return {
            thumbnails: [
                { url: cachedThumbnail, quality: ThumbnailQuality.MaxRes },
            ],
        };
    }

    try {
        const maxresUrl = `https://i.ytimg.com/vi/${video.id}/maxresdefault.jpg`;
        const hqUrl = `https://i.ytimg.com/vi/${video.id}/hqdefault.jpg`;

        const hqCacheKey = `hq_${cacheKey}`;
        const hasHQ = thumbnailCache.get(hqCacheKey);
        if (hasHQ) {
            return {
                thumbnails: [{ url: hqUrl, quality: ThumbnailQuality.HQ }],
            };
        }

        const maxresAvailable = await checkImageAvailability(maxresUrl);

        if (maxresAvailable) {
            thumbnailCache.set(cacheKey, maxresUrl);
            return {
                thumbnails: [
                    { url: maxresUrl, quality: ThumbnailQuality.MaxRes },
                ],
            };
        }

        thumbnailCache.set(cacheKey, hqUrl);
        thumbnailCache.set(hqCacheKey, 'true');
        return {
            thumbnails: [{ url: hqUrl, quality: ThumbnailQuality.HQ }],
        };
    } catch (error) {
        const hqUrl = `https://i.ytimg.com/vi/${video.id}/hqdefault.jpg`;

        if (process.env.NODE_ENV === 'production') {
            const Sentry = await import('@sentry/nextjs');
            Sentry.captureException(error, {
                extra: {
                    video,
                    timestamp: new Date().toISOString(),
                    error:
                        error instanceof Error
                            ? error.message
                            : 'Failed to fetch',
                    retryAttempts: MAX_RETRIES,
                },
            });
        }

        return {
            thumbnails: [{ url: hqUrl, quality: ThumbnailQuality.HQ }],
            error: t.youtube.errors.fetchFailed,
        };
    }
}
