export enum YouTubeVideoType {
    VIDEO = 'video',
    SHORT = 'short',
    EMBED = 'embed',
}

export interface YouTubeVideo {
    id: string;
    type: YouTubeVideoType;
    canonicalUrl: string;
}

export enum ThumbnailQuality {
    MaxRes = 'maxres',
    HQ = 'hq',
}

export interface Thumbnail {
    url: string;
    quality: ThumbnailQuality;
}

export interface ThumbnailResponse {
    thumbnails?: Thumbnail[];
    error?: string;
}

export class SimpleCache<K extends string, V> {
    private cache: Map<K, V>;
    private readonly maxSize: number;
    private readonly prefix: string;

    constructor(maxSize = 100, prefix = 'thumbgrab_cache_') {
        this.maxSize = maxSize;
        this.prefix = prefix;
        this.cache = new Map();
        this.loadFromStorage();
    }

    private loadFromStorage(): void {
        try {
            const stored = localStorage.getItem(this.prefix + 'data');
            if (stored) {
                const data = JSON.parse(stored) as Record<K, V>;
                this.cache = new Map(Object.entries(data) as [K, V][]);

                if (this.cache.size > this.maxSize) {
                    const entriesToDelete = Array.from(this.cache.keys()).slice(
                        0,
                        this.cache.size - this.maxSize
                    );
                    entriesToDelete.forEach((key) => this.cache.delete(key));
                }
            }
        } catch {
            this.cache = new Map();
        }
    }

    private saveToStorage(): void {
        try {
            const data = Object.fromEntries(this.cache.entries());
            localStorage.setItem(this.prefix + 'data', JSON.stringify(data));
        } catch {
            /* empty */
        }
    }

    get(key: K): V | undefined {
        return this.cache.get(key);
    }

    set(key: K, value: V): void {
        if (this.cache.size >= this.maxSize) {
            const firstKey = Array.from(this.cache.keys())[0];
            if (firstKey) {
                this.cache.delete(firstKey);
            }
        }
        this.cache.set(key, value);
        this.saveToStorage();
    }
}

export interface AnalyticsEvent {
    event: string;
    properties: Record<string, unknown>;
}

export type LocaleCode = 'en' | 'de' | 'en-GB' | 'en-CA' | 'en-AU';

export interface LocaleConfig {
    code: LocaleCode;
    domain: string;
    name: string;
    default?: boolean;
    fallback?: LocaleCode;
    direction?: 'ltr' | 'rtl';
    disabled?: boolean;
}

export interface Translations {
    meta: {
        title: string;
        description: string;
        keywords: string[];
        ogTitle: string;
        ogDescription: string;
    };
    home: {
        title: string;
        subtitle: string;
        aria: {
            mainContent: string;
        };
    };
    youtube: {
        errors: {
            invalidUrl: string;
            invalidVideoId: string;
            fetchFailed: string;
            urlRequired: string;
        };
    };
    form: {
        placeholder: string;
        button: {
            default: string;
            loading: string;
        };
        errors: {
            fetchFailed: string;
        };
        aria: {
            form: string;
            urlInput: string;
            urlField: string;
            clearButton: string;
            submitButton: string;
            loadingButton: string;
            errorMessage: string;
        };
    };
    thumbnail: {
        preview: {
            download: string;
        };
        aria: {
            preview: string;
            section: string;
            loadingState: string;
            downloadButton: string;
        };
    };
    ads: {
        aria: {
            placement: string;
            topAd: string;
            leftSidebarAd: string;
            rightSidebarAd: string;
        };
    };
    offline: {
        title: string;
        message: string;
        retry: string;
    };
    error: {
        default: {
            title: string;
            description: string;
            retry: string;
        };
        notFound: {
            title: string;
            description: string;
            backHome: string;
        };
        aria: {
            errorPage: string;
            retryButton: string;
            errorMessage: string;
        };
    };
    manifest: {
        appName: string;
        shortName: string;
        description: string;
        downloadAction: string;
        downloadDescription: string;
        screenshots: {
            desktop: string;
            mobile: string;
        };
    };
    structuredData: {
        appName: string;
        abstract: string;
        description: string;
    };
}
