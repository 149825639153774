'use client';

import { useState, useCallback, useEffect, useTransition, useRef } from 'react';

import { Loader2, X } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';

import { processYouTubeUrl } from '@/app/actions';
import AutoDownloadHandler from '@/components/thumbnail/auto-download-handler';
import { ThumbnailErrorBoundary } from '@/components/thumbnail/thumbnail-error-boundary';
import { ThumbnailPreview } from '@/components/thumbnail/thumbnail-preview';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { cleanYouTubeUrl } from '@/lib/youtube';
import type { ThumbnailResponse, Translations } from '@/types';

interface ThumbnailFormProps {
    translations: Translations;
}

export function ThumbnailForm({ translations: t }: ThumbnailFormProps) {
    const [isPending, startTransition] = useTransition();
    const [state, setState] = useState<ThumbnailResponse | null>(null);
    const lastUserInputType = useRef<'paste' | 'type' | 'autofill' | null>(
        null
    );
    const [url, setUrl] = useState('');
    const posthog = usePostHog();
    const processingRef = useRef(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    const handleClear = useCallback(() => {
        setUrl('');
        setState(null);
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }
        posthog?.capture('input_cleared', {
            timestamp: new Date().toISOString(),
        });
    }, [posthog]);

    const processUrl = useCallback(
        async (
            urlToProcess: string,
            source: 'paste' | 'submit' | 'autofill'
        ) => {
            if (processingRef.current) return;

            const cleanedUrl = cleanYouTubeUrl(urlToProcess);

            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();

            processingRef.current = true;
            startTransition(async () => {
                try {
                    const formData = new FormData();
                    formData.append('url', cleanedUrl);
                    const result = await processYouTubeUrl(formData, t);

                    if (!abortControllerRef.current?.signal.aborted) {
                        setState(result);
                        posthog?.capture('url_processed', {
                            url: cleanedUrl,
                            source,
                            success: !result.error,
                            timestamp: new Date().toISOString(),
                        });
                    }
                } finally {
                    processingRef.current = false;
                }
            });
        },
        [posthog]
    );

    useEffect(() => {
        const inputElement = document.getElementById(
            'youtube-url'
        ) as HTMLInputElement;
        if (!inputElement) return;

        const handlePaste = async (e: ClipboardEvent) => {
            const text = e.clipboardData?.getData('text');

            if (text) {
                setTimeout(() => {
                    processUrl(text, 'paste');
                }, 0);
            }
        };

        inputElement.addEventListener('paste', handlePaste);
        return () => inputElement.removeEventListener('paste', handlePaste);
    }, [processUrl]);

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newUrl = cleanYouTubeUrl(e.target.value);
            setUrl(newUrl);

            const urlParams = new URLSearchParams(window.location.search);
            const isAutoDownload = urlParams.get('action') === 'download';

            const inputType = (e.nativeEvent as InputEvent).inputType;

            if (inputType === 'insertFromPaste') {
                lastUserInputType.current = 'paste';
            } else if (!inputType && !isAutoDownload) {
                lastUserInputType.current = 'autofill';
            } else {
                lastUserInputType.current = 'type';
            }

            if (
                !isAutoDownload &&
                (lastUserInputType.current === 'paste' ||
                    lastUserInputType.current === 'autofill')
            ) {
                setTimeout(() => {
                    processUrl(
                        newUrl,
                        lastUserInputType.current === 'paste'
                            ? 'paste'
                            : 'autofill'
                    );
                }, 100);
            }
        },
        [processUrl]
    );

    const handleSubmit = useCallback(
        async (formData: FormData) => {
            const urlToProcess = formData.get('url') as string;
            startTransition(async () => {
                const result = await processYouTubeUrl(formData, t);
                if (!abortControllerRef.current?.signal.aborted) {
                    setState(result);

                    const firstThumbnail = result.thumbnails?.[0];
                    if (firstThumbnail) {
                        posthog?.capture('thumbnail_generated', {
                            url: urlToProcess,
                            quality: firstThumbnail.quality,
                        });
                    }
                }
            });
        },
        [posthog, t]
    );

    return (
        <section
            className="w-full max-w-prose space-y-6"
            aria-label={t.form.aria.form}
        >
            <form
                action={handleSubmit}
                className="flex w-full flex-col gap-4 sm:flex-row sm:gap-3"
                aria-label={t.form.aria.urlInput}
            >
                <div className="relative flex-1">
                    <label
                        htmlFor="youtube-url"
                        className="sr-only"
                    >
                        {t.form.aria.urlField}
                    </label>
                    <Input
                        id="youtube-url"
                        type="url"
                        name="url"
                        value={url}
                        onChange={handleInputChange}
                        placeholder={t.form.placeholder}
                        required
                        className="h-12 truncate pr-10 text-base"
                        disabled={isPending}
                        aria-label={t.form.aria.urlField}
                        aria-describedby={
                            state?.error ? 'url-error' : undefined
                        }
                        aria-invalid={state?.error ? 'true' : undefined}
                    />
                    {url && (
                        <button
                            type="button"
                            onClick={handleClear}
                            className="absolute right-3 top-1/2 -translate-y-1/2 rounded p-1.5 text-muted-foreground transition-colors hover:text-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                            aria-label={t.form.aria.clearButton}
                        >
                            <X
                                className="size-4"
                                aria-hidden="true"
                            />
                        </button>
                    )}
                </div>
                <Button
                    type="submit"
                    disabled={isPending}
                    className="h-12 whitespace-nowrap px-6 text-base font-medium sm:px-4"
                    aria-busy={isPending}
                    aria-label={
                        isPending
                            ? t.form.aria.loadingButton
                            : t.form.aria.submitButton
                    }
                >
                    {isPending ? (
                        <>
                            <Loader2
                                className="size-4 animate-spin motion-reduce:animate-none"
                                aria-hidden="true"
                            />
                            <span className="ml-2">
                                {t.form.button.loading}
                            </span>
                        </>
                    ) : (
                        t.form.button.default
                    )}
                </Button>
            </form>

            {state?.error && (
                <div
                    className="rounded-lg bg-destructive/15 p-4 text-base text-destructive"
                    role="alert"
                    aria-label={t.form.aria.errorMessage}
                    id="url-error"
                >
                    {t.form.errors[state.error as keyof typeof t.form.errors] ||
                        state.error}
                </div>
            )}

            {state?.thumbnails && (
                <ThumbnailErrorBoundary
                    reset={() => setState(null)}
                    translations={t}
                >
                    <ThumbnailPreview
                        thumbnails={state.thumbnails}
                        translations={t}
                    />
                </ThumbnailErrorBoundary>
            )}

            <AutoDownloadHandler
                translations={t}
                onResult={(formData) => {
                    setUrl(formData.get('url') as string);
                    handleSubmit(formData);
                }}
            />
        </section>
    );
}

export default ThumbnailForm;
