import { useEffect, useTransition, useRef } from 'react';

import { useSearchParams } from 'next/navigation';

import { usePostHog } from 'posthog-js/react';

import { processYouTubeUrl } from '@/app/actions';
import { cleanYouTubeUrl } from '@/lib/youtube';
import type { Translations } from '@/types';

interface AutoDownloadHandlerProps {
    translations: Translations;
    onResult: (formData: FormData) => void;
}

export default function AutoDownloadHandler({
    translations: t,
    onResult,
}: AutoDownloadHandlerProps) {
    const [isPending, startTransition] = useTransition();
    const searchParams = useSearchParams();
    const posthog = usePostHog();
    const processingRef = useRef(false);
    const lastProcessedUrlRef = useRef<string | null>(null);

    useEffect(() => {
        const action = searchParams.get('action');
        const url = searchParams.get('url');

        if (
            action === 'download' &&
            url &&
            !isPending &&
            !processingRef.current &&
            url !== lastProcessedUrlRef.current
        ) {
            const processDownload = async () => {
                processingRef.current = true;
                const cleanedUrl = cleanYouTubeUrl(url);
                lastProcessedUrlRef.current = url;

                const formData = new FormData();
                formData.append('url', cleanedUrl);

                try {
                    startTransition(async () => {
                        const result = await processYouTubeUrl(formData, t);

                        if (posthog && !posthog.has_opted_out_capturing()) {
                            const eventData = {
                                url: cleanedUrl,
                                timestamp: new Date().toISOString(),
                            };

                            if (result.thumbnails?.[0]) {
                                posthog.capture('auto_download_initiated', {
                                    ...eventData,
                                    success: true,
                                });
                                onResult(formData);
                            } else {
                                posthog.capture('auto_download_failed', {
                                    ...eventData,
                                    error: result.error,
                                });
                            }
                        } else {
                            if (result.thumbnails?.[0]) {
                                onResult(formData);
                            }
                        }
                    });
                } catch (error) {
                    if (posthog && !posthog.has_opted_out_capturing()) {
                        posthog.capture('auto_download_error', {
                            url: cleanedUrl,
                            error:
                                error instanceof Error
                                    ? error.message
                                    : 'Unknown error',
                            timestamp: new Date().toISOString(),
                        });
                    }
                    console.error('Auto-download error:', error);
                } finally {
                    processingRef.current = false;
                }
            };

            processDownload();
        }
    }, [searchParams, onResult, isPending, posthog]);

    return null;
}
