'use client';

import { memo, useCallback } from 'react';

import Image from 'next/image';

import { usePostHog } from 'posthog-js/react';

import { AdPlaceholder } from '@/components/layout/ad-placeholder';
import { Button } from '@/components/ui/button';
import { ThumbnailQuality, type Thumbnail, type Translations } from '@/types';

interface ThumbnailImageProps {
    url: string;
    alt: string;
}

interface ThumbnailPreviewProps {
    thumbnails: Thumbnail[];
    translations: Translations;
}

const ThumbnailImage = memo(function ThumbnailImage({
    url,
    alt,
}: ThumbnailImageProps) {
    return (
        <div className="relative aspect-video w-full overflow-hidden rounded-md bg-muted">
            <Image
                src={url}
                alt={alt}
                fill
                className="object-cover"
                priority
                sizes="(max-width: 640px) 100vw, (max-width: 768px) 80vw, (max-width: 1024px) 50vw, 33vw"
                unoptimized
            />
        </div>
    );
});

ThumbnailImage.displayName = 'ThumbnailImage';

export const ThumbnailPreview = memo(function ThumbnailPreview({
    thumbnails,
    translations: t,
}: ThumbnailPreviewProps) {
    const posthog = usePostHog();

    const handleDownload = useCallback(() => {
        const thumbnail = thumbnails[0];
        if (!thumbnail) return;

        posthog?.capture('thumbnail_downloaded', {
            quality: thumbnail.quality,
            timestamp: new Date().toISOString(),
        });
    }, [thumbnails, posthog]);

    if (!thumbnails.length) {
        return (
            <div
                className="aspect-video w-full animate-pulse rounded-md bg-muted motion-reduce:animate-none"
                role="status"
                aria-label={t.thumbnail.aria.loadingState}
            />
        );
    }

    const thumbnail = thumbnails[0];
    if (!thumbnail) return null;

    const quality = thumbnail.quality === ThumbnailQuality.MaxRes ? 'HD' : 'HQ';

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleDownload();
            const anchor = event.currentTarget.querySelector('a');
            anchor?.click();
        }
    };

    return (
        <section
            className="w-full space-y-6"
            aria-label={t.thumbnail.aria.section}
        >
            <div className="space-y-4">
                <ThumbnailImage
                    url={thumbnail.url}
                    alt={t.thumbnail.aria.preview}
                />
                <Button
                    asChild
                    className="w-full bg-success px-6 py-4 text-base font-medium text-white hover:bg-success/90 focus-visible:ring-2 focus-visible:ring-success focus-visible:ring-offset-2"
                    onKeyDown={handleKeyDown}
                    onClick={handleDownload}
                >
                    <a
                        href={thumbnail.url}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex w-full items-center justify-center"
                        aria-label={t.thumbnail.aria.downloadButton.replace(
                            '{quality}',
                            quality
                        )}
                    >
                        {t.thumbnail.preview.download.replace(
                            '{quality}',
                            quality
                        )}
                    </a>
                </Button>
            </div>
            <AdPlaceholder
                className="min-h-24 w-full"
                aria-label={t.ads.aria.placement}
            />
        </section>
    );
});

ThumbnailPreview.displayName = 'ThumbnailPreview';
