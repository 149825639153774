interface AdPlaceholderProps {
    className?: string;
    'aria-label'?: string;
}

export function AdPlaceholder({
    className,
    'aria-label': ariaLabel,
}: AdPlaceholderProps) {
    return (
        <aside
            className={`rounded-lg bg-muted ${className}`}
            aria-label={ariaLabel || 'Advertisement placement'}
        />
    );
}
